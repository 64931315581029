import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import algoliasearch from "algoliasearch";

import { DATA_TYPE, ITEMS } from '../../../../utils/constants';
import { H2 } from '../../../../utils/styles/text';
import ItemsManager from '../../../items-manager/ItemsManager';
import { getDoc, doc } from "firebase/firestore";
import { firestore } from '../../../../Fire';
import { Spinner } from '../../../../utils/styles/images';

function ManageShops(props) {
    const [loading, setLoading] = useState({ 
        clients: true,
        users: true,
    }); 
    const [users, setUsers] = useState([]);
    const [dependents, setDependents] = useState([]);
    
    const [fetched, setFetched] = useState({ 
        data: false,
    });
    
    const [itemStructure, setItemStructure] = useState(ITEMS.SHOPS.STRUCTURE);
    
    useEffect(() => {
        const algoliaClient = algoliasearch(
            process.env.REACT_APP_ALGOLIA_APP_ID,
            props?.customClaims?.algoliaSecuredKey ?? "",
        );
        async function fetchUsersData() {
            const tempUserIds = [];
            let tempUsers = [];

            // Look through roles to see which are isAdmin, add that role name to an array which we will loop through to get that role's users from private doc
            const adminRoleNames = [];
            props.roles.forEach(role => {
                if(role.isAdmin){
                    adminRoleNames.push(role.name);
                }
            });

            console.log("adminRoleNames: ");
            console.log(adminRoleNames);

            const privateDocRef = doc(firestore, "site", "private");
            const privateDocSnap = await getDoc(privateDocRef);
            if (privateDocSnap.exists()) {
                adminRoleNames.forEach(roleName => {
                    // Merge array of users from this admin role, with other admin roles.
                    tempUsers = tempUsers.concat(privateDocSnap.data()[roleName]);
                })
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document for private!");
            }

            // Also merge super admins
            tempUsers = tempUsers.concat(privateDocSnap.data().super);

            console.log("tempUsers: ");
            console.log(tempUsers);
            
            // We will need users for grabbing appropriate user data like the Bird email, and full name for internalContacts
            setUsers(tempUsers);

            // Organize the userIds into a simple array for the options, 
            // but first we need to loop through the array sub columns, as there may be multiple selects to update!
            // Note: this difference in dealing with bird is unique to a select input of users
            let tempDataStruct = [...itemStructure];

            tempUsers.forEach((user) => {
                tempUserIds.push(user.id);
            });
            itemStructure.forEach((itemColumn, s) => {
                if (itemColumn.type === DATA_TYPE.ARRAY) {
                    itemColumn.subColumns.forEach((subColumn, c) => {
                        if(subColumn.key === "bird") {
                            tempDataStruct[s].subColumns[c].options = tempUserIds;
                        }
                    })
                }
            });

            console.log("tempDataStruct: ");
            console.log(tempDataStruct);

            // Set the default internal contat to be this user since they likely will always be involved
            const indexOfInternalContacts = tempDataStruct.findIndex(data => data.key === "internalContacts")
            tempDataStruct[indexOfInternalContacts].initialValue = [
                {
                    bird: props.user.id,
                }
            ];
            
            setItemStructure(tempDataStruct);

            setLoading(prevState => ({
                ...prevState,
                users: false
            }));
        };

        async function fetchClientsData() {
            try {
                let tempClients = [];
                let tempClientNames = [];

                const baseIndex = process.env.NODE_ENV === 'development' ? "test_clients" : "clients";
                let doesBaseIndexExist = false;
                await algoliaClient.initIndex(baseIndex).exists().then(response => {
                    doesBaseIndexExist = response;
                });

                if (doesBaseIndexExist) {
                    const itemIndex = algoliaClient.initIndex(baseIndex);
                    await itemIndex.search("", {
                        hitsPerPage: 1000,
                    }).then((response) => {
                        console.log("Algolia Query results:");
                        console.log(response);
                        tempClients = response.hits;
                        response.hits.forEach((hit) => {
                            tempClientNames.push(hit.name);
                        });
                    }).catch((error) => {
                        console.error("Error with Algolia search: " + error.message);
                        toast.error("Error with search: " + error.message);
                    });
                    
                    const clientIndexToUpdate = itemStructure.findIndex((data) => data.key === "client");
                    const tempDataStruct = [...itemStructure];
                    tempDataStruct[clientIndexToUpdate].options = tempClientNames;
                    setItemStructure(tempDataStruct);
                    setDependents([
                        {
                            parentKey: "client",
                            childKey: "poc",
                            parentArray: tempClients,
                            parentArrayOptionArrayKey: "contacts",
                            parentArrayOptionLabelKey: "name",
                        }
                    ]);
                } else {
                    toast.error("Clients base index doesn't exist yet.");
                }
                
                setLoading(prevState => ({
                    ...prevState,
                    clients: false
                }));
            } catch (error) {
                console.error("Error grabbing dependent items: ");
                console.error(error);
                
                setLoading(prevState => ({
                    ...prevState,
                    clients: false
                }));
            }
        };

        if (!fetched.data) {
            fetchClientsData();
            fetchUsersData();
            setFetched(prevState => ({
                ...prevState,
                data: true
            }));
        }

    }, [fetched.data, itemStructure, props.roles, props.customClaims, props.user.id]); 

    if (loading.clients) {
        return (
            <H2>Loading... <Spinner /> </H2> 
        )
    } else {
        return (
            <ItemsManager 
                pageTitle="Shops"
                itemCollection={ITEMS.SHOPS.COLLECTION}
                itemName={ITEMS.SHOPS.NAME}
                isUnique={true}
                users={users}
                itemStructure={itemStructure}
                setItemStructure={setItemStructure}
                // defaultFormValues={defaultFormValues}
                dependents={dependents}
                {...props}
            />
        )
    }
    
}

export default ManageShops;