import React, { useEffect, useState} from 'react'
import algoliasearch from 'algoliasearch';

import { ITEMS, } from '../../../../utils/constants';
import ItemsManager from '../../../items-manager/ItemsManager';
import { toast } from 'react-toastify';
import { H2 } from '../../../../utils/styles/text';
import { Spinner } from '../../../../utils/styles/images';

function ManageProducts(props) {
    const [itemStructure, setItemStructure] = useState(ITEMS.PRODUCTS.STRUCTURE);
    const [loading, setLoading] = useState({ 
        clients: true,
        shops: true,
    }); 
    
    const [fetched, setFetched] = useState({ 
        data: false,
    });
    
    useEffect(() => {
        const algoliaClient = algoliasearch(
            process.env.REACT_APP_ALGOLIA_APP_ID,
            props?.customClaims?.algoliaSecuredKey ?? "",
        );

        async function fetchClients() {
            try {
                let tempClientNames = [];

                const baseIndex = process.env.NODE_ENV === 'development' ? "test_clients" : "clients";
                let doesBaseIndexExist = false;
                await algoliaClient.initIndex(baseIndex).exists().then(response => {
                    doesBaseIndexExist = response;
                });

                if (doesBaseIndexExist) {
                    const itemIndex = algoliaClient.initIndex(baseIndex);
                    await itemIndex.search("", {
                        hitsPerPage: 1000,
                    }).then((response) => {
                        console.log("Algolia Query results:");
                        console.log(response);
                        response.hits.forEach((hit) => {
                            tempClientNames.push(hit.name);
                        });
                    }).catch((error) => {
                        console.error("Error with Algolia search on client search: " + error.message);
                        toast.error("Error with search: " + error.message);
                    });
                    
                    const clientIndexToUpdate = itemStructure.findIndex((data) => data.key === "client");
                    const tempDataStruct = [...itemStructure];
                    tempDataStruct[clientIndexToUpdate].options = tempClientNames;
                    setItemStructure(tempDataStruct);
                } else {
                    toast.error("Clients base index doesn't exist yet.");
                }
                
                setLoading(prevState => ({
                    ...prevState,
                    clients: false
                }));
            } catch (error) {
                console.error("Error grabbing dependent items: ");
                console.error(error);
                
                setLoading(prevState => ({
                    ...prevState,
                    clients: false
                }));
            }
        };

        async function fetchShops() {
            try {
                let tempShopNames = [];

                const baseIndex = process.env.NODE_ENV === 'development' ? "test_shops" : "shops";
                let doesBaseIndexExist = false;
                await algoliaClient.initIndex(baseIndex).exists().then(response => {
                    doesBaseIndexExist = response;
                });

                if (doesBaseIndexExist) {
                    const itemIndex = algoliaClient.initIndex(baseIndex);
                    await itemIndex.search("", {
                        hitsPerPage: 1000,
                    }).then((response) => {
                        console.log("Algolia Query results:");
                        console.log(response);
                        response.hits.forEach((hit) => {
                            tempShopNames.push(hit.name);
                        });
                    }).catch((error) => {
                        console.error("Error with Algolia search on shop search: " + error.message);
                        toast.error("Error with search: " + error.message);
                    });
                    
                    const shopIndexToUpdate = itemStructure.findIndex((data) => data.key === "shop");
                    const tempDataStruct = [...itemStructure];
                    tempDataStruct[shopIndexToUpdate].options = tempShopNames;
                    setItemStructure(tempDataStruct);
                } else {
                    toast.error("Shops base index doesn't exist yet.");
                }
                
                setLoading(prevState => ({
                    ...prevState,
                    shops: false
                }));
            } catch (error) {
                console.error("Error grabbing dependent items: ");
                console.error(error);
                
                setLoading(prevState => ({
                    ...prevState,
                    shops: false
                }));
            }
        };

        if (!fetched.data) {
            fetchClients();
            fetchShops();
            setFetched(prevState => ({
                ...prevState,
                data: true
            }));
        }

    }, [fetched.data, itemStructure, props.customClaims.algoliaSecuredKey]); 

    if (loading.clients || loading.shops) {
        return (
            <H2>Loading... <Spinner /> </H2> 
        )
    } else {
        return (
            <ItemsManager 
                pageTitle="Products"
                itemCollection={ITEMS.PRODUCTS.COLLECTION}
                itemName={ITEMS.PRODUCTS.NAME}
                itemStructure={itemStructure}
                setItemStructure={setItemStructure}
                {...props}
            />
        )
    }
   
}

export default ManageProducts;