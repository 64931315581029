import React, { useEffect, useState} from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../../../Fire';
import { ITEMS } from '../../../../utils/constants';
import { Spinner } from '../../../../utils/styles/images';
import { H2 } from '../../../../utils/styles/text';

import ItemsManager from '../../../items-manager/ItemsManager';

function ManageClients(props) {
    const [loading, setLoading] = useState({ 
        users: true,
    }); 
    
    const [fetched, setFetched] = useState({ 
        users: false,
    });
    const [users, setUsers] = useState([]);

    const [itemStructure, setItemStructure] = useState(ITEMS.CLIENTS.STRUCTURE);

    useEffect(() => {
        async function fetchData() {
            const tempUserIds = [];
            let tempUsers = [];

            // Look through roles to see which are isAdmin, add that role name to an array which we will loop through to get that role's users from private doc
            const adminRoleNames = [];
            props.roles.forEach(role => {
                if (role.isAdmin) {
                    adminRoleNames.push(role.name);
                }
            });

            console.log("adminRoleNames: ");
            console.log(adminRoleNames);

            const privateDocRef = doc(firestore, "site", "private");
            const privateDocSnap = await getDoc(privateDocRef);
            if (privateDocSnap.exists()) {
                adminRoleNames.forEach(roleName => {
                    // Merge array of users from this admin role, with other admin roles.
                    tempUsers = tempUsers.concat(privateDocSnap.data()[roleName]);
                })
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document for private!");
            }

            // Also merge super admins
            tempUsers = tempUsers.concat(privateDocSnap.data().super);

            console.log("tempUsers: ");
            console.log(tempUsers);

            // We will need users for grabbing appropriate user data like the Bird email, and full name for internalContacts
            setUsers(tempUsers);

            // Organize the userIds into a simple array for the options, 
            // but first we need to loop through the array sub columns, as there may be multiple selects to update!
            // Note: this difference in dealing with bird is unique to a select input of users
            let tempDataStruct = [...itemStructure];

            tempUsers.forEach((user) => {
                tempUserIds.push(user.id);
            });

            console.log("tempDataStruct: ");
            console.log(tempDataStruct);

            // Organize the userIds into a simple array for the options
            const indexOfKeyToUpdate = tempDataStruct.findIndex(data => data.key === "leadBird");

            tempDataStruct[indexOfKeyToUpdate].options = tempUserIds;
            
            setItemStructure(tempDataStruct);

            setLoading(prevState => ({
                ...prevState,
                users: false
            }));
        };

        
        if (!fetched.users) {
            fetchData();
            setFetched(prevState => ({
                ...prevState,
                users: true
            }));
        }

    }, [fetched.users, itemStructure, props.roles]); 

    if (loading.clients || loading.users) {
        return (
            <H2>Loading... <Spinner /> </H2> 
        )
    } else {
        return (
            <ItemsManager 
                pageTitle="Clients"
                itemCollection={ITEMS.CLIENTS.COLLECTION}
                itemName={ITEMS.CLIENTS.NAME}
                isUnique={true}
                users={users}
                itemStructure={itemStructure}
                setItemStructure={setItemStructure}
                {...props}
            />
        )
    }

    
}

export default ManageClients;