import { BiMessageAltDetail, BiPackage } from "react-icons/bi";
import { FaShoppingCart, FaTshirt } from "react-icons/fa";
import { MdBusiness, MdOutlineFeedback } from "react-icons/md";
import { RiPagesLine } from "react-icons/ri";
import { isColor } from "./misc";

// Front-end only //
export const BTYPES = {
    INVERTED: "inverted",
    TEXTED: "texted",
}

export const ANIMAL_GALLERY = [
    {
        src: require("../assets/images/misc/animals/1.png"),
        alt: "link cat couch"
    },
    {
        src: require("../assets/images/misc/animals/2.png"),
        alt: "zelda cat couch"
    },
    {
        src: require("../assets/images/misc/animals/3.png"),
        alt: "link cat laundry"
    },
    {
        src: require("../assets/images/misc/animals/4.png"),
        alt: "georgie dog sunglasses"
    },
    {
        src: require("../assets/images/misc/animals/5.png"),
        alt: "zelda cat sleeping"
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/test-fire-react-base.appspot.com/o/public%2Fmisc%2F6.png?alt=media&token=665c362c-716e-46fd-8aa9-c0582a897dd1",
        alt: "link cat table"
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/test-fire-react-base.appspot.com/o/public%2Fmisc%2F7.png?alt=media&token=be7179dd-bf38-44e2-837d-54d4a445d383",
        alt: "zelda cat lap"
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/test-fire-react-base.appspot.com/o/public%2Fmisc%2F8.png?alt=media&token=265e4b18-35ab-45c5-9b7a-066469e0383e",
        alt: "georgie dog couch"
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/test-fire-react-base.appspot.com/o/public%2Fmisc%2F9.png?alt=media&token=0ba93d22-580a-436c-8818-510f7f98bfae",
        alt: "zelda cat desk"
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/test-fire-react-base.appspot.com/o/public%2Fmisc%2F10.png?alt=media&token=0cb38dd8-60d2-416c-8608-f70adb4a2844",
        alt: "zelda cat desk"
    },
];

export const PAGE_SIZES = [10, 25, 50, 100,];

export const APHORISMS = [
    "The world is your oyster.",
    "You can do anything you set your mind to.",
    "Nothing is impossible to a willing heart.",
    // "The best things in life are free.",
    "Don't pursue happiness - create it.",
    "The real kindness comes from within you.",
    "The usefulness of a cup can be in its emptiness.",
    "Big journeys begin with a single step.",
    "Happiness isn't an outside job, it's an inside job.",
    "You are beautiful inside and out.",
    "A lifetime of happiness lies ahead of you.",
    "A pleasant surprise is waiting for you.",
    "Adventure can be real happiness.",
    "Allow compassion to guide your decisions.",
    "Believe it can be done.",
    "Courtesy is contagious.",
    "Every flower blooms in time.",
    "Have a wonderful day.",
    "Love is a warm fire to keep the soul warm.",
    "Miles are covered one step at a time.",
    "You are in good hands today.",
    "You are talented in many ways.",
    "Practice makes perfect.",
    "Love lights up the world.",
    "You look like a million bucks today.",
    "A stranger is a friend you have not spoken to yet.",
    // "Everyone agrees. You are the best.",
    "Now is the time to try something new.",
    // "Sometimes you just need to lay on the floor.",
    // "The greatest risk is not taking one.",
    "You can create happiness."
];

/////////////////// Back-end also //////////////////////
export const SIZES = {
    XS: "xs",
    SM: "sm",
    MD: "md",
    LG: "lg",
    XL: "xl",
    XXL: "2xl",
    XXXL: "3xl",
};

export const RESERVED_ROLES = [
    "super",
    "bccEmailGroups",
    "ccEmailGroups",
    // Update these item collections if they change!
    "messages",
    "feedback",
    "clients",
    "shops",
    "products",
    "orders",
    "users",
    "roles",
    "pages",
    // custom claim reserved tokens: https://firebase.google.com/docs/auth/admin/create-custom-tokens#reserved_custom_token_names
    "acr",
    "amr",
    "auth_time",
    "azp",
    "cnf",
    "c_hash",
    "aud",
    "at_hash",
    "exp",
    "iat",
    "iss",
    "jti",
    "nbf",
    "nonce",
    "sub",
    "firebase",
    "user_id",
];

export const DEFAULT_SITE = {
    NAME: "Fire React Base",
    DESCRIPTION: "Fire React Base is a template for creating web apps with Firebase and React.js.",
    PROJECT_ID: "fire-react-base",
    CUSTOM_URL: "",
    EMAILS: {
        SUPPORT: "help@canarymarketing.com",
        NOREPLY: "noreply@canary.contact",
    },
    LOGO: {
        WIDTH: 100,
        HEIGHT: 100,
        LIGHT_URL: "https://firebasestorage.googleapis.com/v0/b/test-fire-react-base.appspot.com/o/public%2Flogos%2Flogo-dark.png?alt=media&token=1483f9fd-0cc4-4e4d-b737-2042cfef05f7",
        DARK_URL: "https://firebasestorage.googleapis.com/v0/b/test-fire-react-base.appspot.com/o/public%2Flogos%2Flogo192.png?alt=media&token=d327bc99-6ee8-496e-86c7-0206244b837b",
        FAVICON: "https://firebasestorage.googleapis.com/v0/b/test-fire-react-base.appspot.com/o/public%2Flogos%2Ffavicon.ico?alt=media&token=3f463d53-9d63-4e47-864f-8ad01df23638",
        APPLE_TOUCH_ICON: "https://firebasestorage.googleapis.com/v0/b/test-fire-react-base.appspot.com/o/public%2Flogos%2Fapple-touch-icon.png?alt=media&token=fa68e408-7fe8-4c3f-91b4-bdbdabe46e9a",
        SHOW_TITLE: true,
    },
    THEME: {
        FONT: {
            HEADING: {
                NAME: "Roboto Black",
                URL: "",
            },
            SUBHEADING: {
                NAME: "Roboto Bold",
                URL: "",
            },
            BODY: {
                NAME: "Roboto Regular",
                URL: "",
            },
        },
        COLOR: {
            LIGHT: {
                ENABLED: true,
                PRIMARY: "dodgerblue",
                SECONDARY: "purple",
                TERTIARY: "tomato",
                RED: "firebrick",
                GREEN: "green",
                YELLOW: "gold",
                GREY: "grey",
                LIGHT_GREY: "lightgrey",
                BLUE: "navy",
                BACKGROUND: "white",
                FONT: {
                    HEADING: "black",
                    BODY: "black",
                    LINK: "navy",
                    SOLID: "white",
                    INVERTED: "dodgerblue",
                },
            },
            DARK: {
                ENABLED: true,
                PRIMARY: "dodgerblue",
                SECONDARY: "purple",
                TERTIARY: "tomato",
                RED: "firebrick",
                GREEN: "green",
                YELLOW: "gold",
                GREY: "grey",
                LIGHT_GREY: "lightgrey",
                BLUE: "navy",
                BACKGROUND: "black",
                FONT: {
                    HEADING: "white",
                    BODY: "white",
                    LINK: "lightblue",
                    SOLID: "white",
                    INVERTED: "dodgerblue",
                },
            },
        },
    },
    HERO: {
        HEADING: "Hero Section",
        BODY: `This is the homepage hero section, customize it as you please, please. Dolore irure deserunt occaecat tempor. 
        Dolore reprehenderit ut consequat anim officia amet. Laboris officia ea eu elit consectetur sit dolor duis adipisicing reprehenderit reprehenderit deserunt reprehenderit quis. 
        Fugiat est reprehenderit quis labore aute anim in labore officia non ut aliquip mollit. In laboris amet amet occaecat. Laboris minim culpa cillum veniam adipisicing et deserunt sit.`,
        CTA: {
            LINK: "/gift-ship-form",
            TEXT: "Order a Gift",
            SIZE: SIZES.LG,
            COLOR: "black",
        },
        BANNER: "https://firebasestorage.googleapis.com/v0/b/test-fire-react-base.appspot.com/o/public%2Fbanners%2FDSC_0047.JPG?alt=media&token=8d4ff53c-11c2-4849-9479-6cd091598635",
    },
    ALERT: {
        TEXT: "This is a test alert, please ignore.",
        BACKGROUND: "red",
        LINK: "https://www.minute.tech",
        IS_HIDDEN: true,
    },
    MENUS: {
        HEADER: [
            {
                name: "Home",
                link: "/",
            },
            {
                name: "About",
                link: "/about",
            },
            {
                name: "Dashboard",
                link: "/Dashboard",
            },
            {
                name: "Minute.tech",
                link: "https://www.minute.tech",
            },
        ],
        QUICK_TABS: [
            {
                name: "General",
            },
            {
                name: "HR",
            },
            {
                name: "Social Media",
            },
        ],
        QUICK_LINKS: [
            {
                name: "Help Desk",
                link: "https://www.minute.tech",
                tab: "General",
                icon: "",
                color: "",
            },
            {
                name: "Training",
                link: "https://www.tovutilms.com/",
                tab: "General",
                icon: "",
                color: "green",
            },
            {
                name: "Office Vibe",
                link: "https://login.officevibe.com",
                tab: "HR",
                icon: "FiSmile",
                color: "",
            },
            {
                name: "PayChex",
                link: "https://www.paychex.com",
                tab: "HR",
                icon: "FiBriefcase",
                color: "",
            },
            {
                name: "Instagram",
                link: "https://www.instagram.com",
                tab: "Social Media",
                icon: "",
                color: "brown",
            },
            {
                name: "LinkedIn",
                link: "https://www.linkedin.com",
                tab: "Social Media",
                icon: "",
                color: "navy",
            },
            {
                name: "TikTok",
                link: "https://www.tiktok.com",
                tab: "Social Media",
                icon: "",
                color: "black",
            },
            {
                name: "Facebook",
                link: "https://www.facebook.com",
                tab: "Social Media",
                icon: "",
                color: "dodgerblue",
            },
            {
                name: "Twitter",
                link: "https://www.twitter.com",
                tab: "Social Media",
                icon: "",
                color: "dodgerblue",
            },
        ],
        FOOTER: [],
    },
};

export const SCHEMES = {
    LIGHT: "light",
    DARK: "dark",
};

export const ADMIN = {
    SUPER: "super",
    ROLE: "role",
    RECREATED: "recreated",
};

export const USER_STRUCTURE = {
    NAME: "name",
    EMAIL: "email",
};

export const ORDER_PRODUCT_STRUCTURE = {
    IMAGES: "images",
    NAME: "name",
    SKU: "sku",
    CLIENT: "client",
    SHOP: "shop",
    CATEGORY: "category",
    DESCRIPTION: "description",
    QUANTITY: "quantity",
    OPTION_CHOICES: "optionChoices"
};

export const ORDER_EXPORT_STRUCTURE = [
    {label: "First Name", key: "firstName" },
    {label: "Last Name", key: "lastName" },
    {label: "Email", key: "email" },
    {label: "Phone", key: "phone"},
    {label: "Address 1", key: "line1"},
    {label: "Address 2", key: "line2"},
    {label: "Address 3", key: "line3"},
    {label: "City", key: "city"},
    {label: "State", key: "state"},
    {label: "Postal Code", key: "zip"},
    {label: "Country", key: "country"},
    {label: "Client", key: "client"},
    {label: "Shop", key: "shop"},
    {label: "Product", key: "product"},
    {label: "Sku", key: "sku"},
];



export const DATA_TYPE = {
    TEXT: "text",
    TEXTAREA: "textarea",
    SELECT: "select",
    CHECKBOX: "checkbox",
    RADIO: "radio",
    ARRAY: "array",
    TIMESTAMP: "timestamp",
    OBJECT: "object",
    NUMBER: "number",
    IMAGES: "images",
    RICH_TEXT: "rich-text",
};

export const CRUD = {
    READ: "read",
    CREATE: "create",
    UPDATE: "update",
    DELETE: "delete",
    DUPLICATE: "duplicate",
    CC_ON_CREATE: "CC on create",
    BCC_ON_CREATE: "BCC on create",
};

export const INPUT = {
    EMAIL: {
        KEY: "email",
        LABEL: "Email",
        PLACEHOLDER: "taylor_doe@email.com",
        ERRORS: {
            REQUIRED: "An email is required!",
            PATTERN: {
                MESSAGE: "This doesn't look like a valid email address.",
                KEY: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            },
            TAKEN: {
                MESSAGE: "Email already registered! Try logging in or use another email address.",
                TYPE: "taken",
            },
        },
    },
    PHONE: {
        KEY: "phone",
        LABEL: "Phone",
        PLACEHOLDER: "+1 (123) 456-7890",
        ERRORS: {
            REQUIRED: "A phone number is required!",
            MAX: {
                MESSAGE: "The phone number can only be 25 characters long.",
                KEY: 25,
            },
            MIN: {
                MESSAGE: "The phone number must be at least 4 characters long.",
                KEY: 4,
            },
        },
    },
    PASSWORD: {
        KEY: "password",
        LABEL: "Password",
        PLACEHOLDER: "*********************",
        ERRORS: {
            REQUIRED: "A password is required!",
            MAX: {
                MESSAGE: "The password can only be 50 characters long.",
                KEY: 50,
            },
            MIN: {
                MESSAGE: "The password must be at least 6 characters long.",
                KEY: 6,
            },
        },
    },
    CONFIRM_PASSWORD: {
        KEY: "confirmPassword",
        LABEL: "Confirm password",
        PLACEHOLDER: "*********************",
        ERRORS: {
            REQUIRED: "The password must be confirmed!",
            MAX: {
                MESSAGE: "The password can only be 50 characters long.",
                KEY: 50,
            },
            MIN: {
                MESSAGE: "The password must be at least 6 characters long.",
                KEY: 6,
            },
            NO_MATCH: {
                TYPE: "no-match",
                MESSAGE: "The passwords entered must match!",
            },
        },
    },
    FIRST_NAME: {
        KEY: "firstName",
        LABEL: "First name",
        PLACEHOLDER: "Taylor",
        ERRORS: {
            REQUIRED: "A first name is required!",
            MAX: {
                MESSAGE: "The first name can only be 150 characters long.",
                KEY: 150,
            },
            MIN: {
                MESSAGE: "The first name must be at least 1 characters long.",
                KEY: 1,
            },
        },
    },
    LAST_NAME: {
        KEY: "lastName",
        LABEL: "Last name",
        PLACEHOLDER: "Doe",
        ERRORS: {
            REQUIRED: "A last name is required!",
            MAX: {
                MESSAGE: "The last name can only be 150 characters long.",
                KEY: 150,
            },
            MIN: {
                MESSAGE: "The last name must be at least 1 characters long.",
                KEY: 1,
            },
        },
    },
    NAME: {
        KEY: "name",
        LABEL: "Name",
        PLACEHOLDER: "Taylor Doe",
        ERRORS: {
            REQUIRED: "A name is required!",
            MAX: {
                MESSAGE: "The name can only be 150 characters long.",
                KEY: 150,
            },
            MIN: {
                MESSAGE: "The name must be at least 1 characters long.",
                KEY: 1,
            },
        },
    },
    ADDRESS: {
        LINE1: {
            KEY: "line1",
            LABEL: "Street Line 1",
            PLACEHOLDER: "123 Main Street",
            ERRORS: {
                REQUIRED: "A street address is required!",
                MAX: {
                    MESSAGE: "The street address can only be 35 characters long.",
                    KEY: 35,
                },
                MIN: {
                    MESSAGE: "The street address must be at least 2 characters long.",
                    KEY: 2,
                },
            },
        },
        LINE2: {
            KEY: "line2",
            LABEL: "Street Line 2",
            PLACEHOLDER: "Apt, suite, unit, building, floor, etc.",
            ERRORS: {
                MAX: {
                    MESSAGE: "The field can only be 35 characters long.",
                    KEY: 35,
                },
            },
        },
        LINE3: {
            KEY: "line3",
            LABEL: "Street Line 3",
            PLACEHOLDER: "Non-US address information",
            ERRORS: {
                MAX: {
                    MESSAGE: "The field can only be 35 characters long.",
                    KEY: 35,
                },
            },
        },
        CITY: {
            KEY: "city",
            LABEL: "City",
            PLACEHOLDER: "San Francisco",
            ERRORS: {
                REQUIRED: "A city is required!",
                MAX: {
                    MESSAGE: "The city can only be 35 characters long.",
                    KEY: 35,
                },
                MIN: {
                    MESSAGE: "The city must be at least 2 characters long.",
                    KEY: 2,
                },
            },
        },
        STATE: {
            KEY: "state",
            LABEL: "State",
            PLACEHOLDER: "CA",
            ERRORS: {
                REQUIRED: "A state is required!",
                MAX: {
                    MESSAGE: "The state can only be 35 characters long.",
                    KEY: 35,
                },
                MIN: {
                    MESSAGE: "The state must be at least 2 characters long.",
                    KEY: 2,
                },
            },
        },
        ZIP: {
            KEY: "zip",
            LABEL: "Postal Code",
            PLACEHOLDER: "12345",
            ERRORS: {
                REQUIRED: "A postal code is required!",
                MAX: {
                    MESSAGE: "The postal code can only be 35 characters long.",
                    KEY: 35,
                },
                MIN: {
                    MESSAGE: "The postal code must be at least 2 characters long.",
                    KEY: 2,
                },
            },
        },
        COUNTRY: {
            KEY: "country",
            LABEL: "Country",
            PLACEHOLDER: "US",
            ERRORS: {
                REQUIRED: "A country is required!",
                MAX: {
                    MESSAGE: "The country can only be 2 characters long.",
                    KEY: 2,
                },
                MIN: {
                    MESSAGE: "The state must be at least 2 characters long.",
                    KEY: 2,
                },
            },
        },
    },
    BODY: {
        KEY: "body",
        LABEL: "Message body",
        PLACEHOLDER: "Detail what you want to say here.",
        ERRORS: {
            REQUIRED: "A text body is required!",
            MAX: {
                MESSAGE: "The text body can only be 30,000 characters long.",
                KEY: 30000,
            },
            MIN: {
                MESSAGE: "The text body must be at least 10 characters long.",
                KEY: 10,
            },
        },
    },
    NOTES: {
        KEY: "notes",
        LABEL: "Notes",
        PLACEHOLDER: "Jot down some notes such as major changes, unique characteristics, etc that may have been missed and would be helpful for the rest of the team to know.",
        TOOLTIP: "This notes field will always be internal use only and will not be displayed to the public.",
    },
    COLOR: {
        KEY: "color",
        LABEL: "Color",
        PLACEHOLDER: "#FFFFFF",
        ERRORS: {
            REQUIRED: "A color is required!",
            MAX: {
                MESSAGE: "The color can only be 15 characters long.",
                KEY: 15,
            },
            MIN: {
                MESSAGE: "The color must be at least 2 characters long.",
                KEY: 2,
            },
            VALIDATE: {
                MESSAGE: "Looks like one of the colors you inputted is not a proper HTML color. Try using a hex color like '#FFFFFF'!",
            },
        },
    },
    URL: {
        KEY: "url",
        LABEL: "URL",
        PLACEHOLDER: "https://www.example.com",
        ERRORS: {
            REQUIRED: "A URL is required!",
            MAX: {
                MESSAGE: "The URL can only be 500 characters long.",
                KEY: 500,
            },
            MIN: {
                MESSAGE: "The URL must be at least 1 characters long.",
                KEY: 1,
            },
            PATTERN: {
                MESSAGE: "This doesn't look like a valid URL address. Please copy the URL directly from your browser and paste it here, including 'http'/'https' portion.",
                // KEY: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g // fixed useless escapes
                KEY: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gi
                // KEY: /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i,
            },
        },
    },
};

export const VARIANT_LAYOUT = {
    SELECT: "Select Dropdown",
    RADIOS: "Radio Buttons",
    BUTTONS: "Box Buttons",
};

// REMEMBER: update any changes by copy pasting this into the constants.ts file in ffunctions!  Just remember to remove the the ICON field in .ts version!
export const ITEMS = {
    MESSAGES: {
        COLLECTION: "messages",
        NAME: "message",
        ICON: <BiMessageAltDetail size={25} />,
        ORDER: 4,
        COLOR: DEFAULT_SITE.THEME.COLOR.LIGHT.GREEN,
        STRUCTURE: [
            {
                label: "Name",
                key: "name",
                shown: true,
                uneditable: true,
                type: DATA_TYPE.TEXT,
            },
            {
                label: "Email",
                key: "email",
                shown: true,
                uneditable: true,
                type: DATA_TYPE.TEXT,
            },
            {
                label: "Body",
                key: "body",
                shown: true,
                uneditable: true,
                type: DATA_TYPE.TEXTAREA,
            },
            {
                label: "Resolved",
                key: "resolved",
                shown: true,
                type: DATA_TYPE.RADIO,
                breakpoints: {
                    xs: 12,
                },
                isBool: true,
                initialValue: "false",
            },
            {
                label: "IP Address",
                key: "ip",
                uneditable: true,
                hideinEmail: true,
                type: DATA_TYPE.TEXT,
            },
            {
                label: INPUT.NOTES.LABEL,
                key: INPUT.NOTES.KEY,
                placeholder: INPUT.NOTES.PLACEHOLDER,
                tooltip: INPUT.NOTES.TOOLTIP,
                type: DATA_TYPE.RICH_TEXT,
                breakpoints: {
                    xs: 12,
                },
            },
        ],
    },
    FEEDBACK: {
        COLLECTION: "feedback",
        NAME: "feedback",
        ICON: <MdOutlineFeedback size={25} />,
        ORDER: 5,
        COLOR: DEFAULT_SITE.THEME.COLOR.LIGHT.RED,
        STRUCTURE: [
            {
                label: "Emotion",
                key: "emotionSymbol",
                hideInModal: true,
                shown: true,
                uneditable: true,
                // Emotion input not allowed yet in ItemManager
            },
            {
                label: "Score out of 100",
                key: "rangeValue",
                shown: true,
                uneditable: true,
                // Range input not allowed yet in ItemManager
            },
            {
                label: "Message",
                key: "body",
                type: DATA_TYPE.TEXTAREA,
                shown: true,
                uneditable: true,
            },
        ],
    },
    CLIENTS: {
        COLLECTION: "clients",
        NAME: "client",
        ICON: <MdBusiness size={25} />,
        ORDER: 0,
        COLOR: DEFAULT_SITE.THEME.COLOR.LIGHT.PRIMARY,
        STRUCTURE: [
            {
                label: "Company Name",
                key: "name",
                shown: true,
                type: DATA_TYPE.TEXT,
                placeholder: "ACME Bread Co.",
                breakpoints: {
                    xs: 12,
                    sm: 6,
                    md: 4,
                },
                validators: {
                    required: INPUT.NAME.ERRORS.REQUIRED,
                    maxLength: {
                        value: INPUT.NAME.ERRORS.MAX.KEY,
                        message: INPUT.NAME.ERRORS.MAX.MESSAGE,
                    },
                    minLength: {
                        value: INPUT.NAME.ERRORS.MIN.KEY,
                        message: INPUT.NAME.ERRORS.MIN.MESSAGE,
                    },
                },
            },
            {
                label: "Client SKU Prefix",
                key: "skuPrefix",
                tooltip: "This will be the beginning of a product's SKU related to this client, such as 'Facebook' being 'FB', which will become 'FB-BDAYB-TSHIRT' for a specific product. ",
                shown: true,
                type: DATA_TYPE.TEXT,
                placeholder: "ACME",
                breakpoints: {
                    xs: 12,
                    sm: 6,
                    md: 4,
                },
                validators: {
                    required: "A SKU prefix is required.",
                    maxLength: {
                        value: 10,
                        message: "The SKU prefix must be at most 10 characters",
                    },
                    minLength: {
                        value: 1,
                        message: "The SKU prefix must be at least 1 characters",
                    },
                },
                initialValue: "",
            },
            {
                label: "Company URL",
                key: INPUT.URL.KEY,
                isUrl: true,
                type: DATA_TYPE.TEXT,
                placeholder: INPUT.URL.PLACEHOLDER,
                breakpoints: {
                    xs: 12,
                    sm: 6,
                    md: 4,
                },
                validators: {
                    required: INPUT.URL.ERRORS.REQUIRED,
                    maxLength: {
                        value: INPUT.URL.ERRORS.MAX.KEY,
                        message: INPUT.URL.ERRORS.MAX.MESSAGE,
                    },
                    minLength: {
                        value: INPUT.URL.ERRORS.MIN.KEY,
                        message: INPUT.URL.ERRORS.MIN.MESSAGE,
                    },
                    pattern: {
                        value: INPUT.URL.ERRORS.PATTERN.KEY,
                        message: INPUT.URL.ERRORS.PATTERN.MESSAGE,
                    },
                },
            },
            {
                label: "Lead Bird",
                key: "leadBird",
                shown: true,
                type: DATA_TYPE.SELECT,
                breakpoints: {
                    xs: 12,
                    sm: 6,
                    md: 4,
                },
                options: ["Loading..."],
                userLookup: true,
                validators: {
                    required: "A lead bird must be selected for this client.",
                },
            },
            {
                label: "Status",
                key: "status",
                shown: true,
                type: DATA_TYPE.SELECT,
                breakpoints: {
                    xs: 12,
                    sm: 6,
                    md: 4,
                },
                options: [
                    "Prospect",
                    "Active",
                    "Expired",
                ],
                validators: {
                    required: "A status must be selected for this client.",
                },
            },
            {
                label: "Client Contacts",
                key: "contacts",
                tooltip: "Who are some people we work with within the client's company? This list will determine the dropdown list of specific contacts for a client project.",
                type: DATA_TYPE.ARRAY,
                validators: {
                    required: "At least one client contact must be added.",
                },
                breakpoints: {
                    xs: 12,
                },
                subColumns: [
                    {
                        label: "Name",
                        key: "name",
                        placeholder: INPUT.NAME.PLACEHOLDER,
                        type: DATA_TYPE.TEXT,
                        validators: {
                            required: INPUT.NAME.ERRORS.REQUIRED,
                            maxLength: {
                                value: INPUT.NAME.ERRORS.MAX.KEY,
                                message: INPUT.NAME.ERRORS.MAX.MESSAGE,
                            },
                            minLength: {
                                value: INPUT.NAME.ERRORS.MIN.KEY,
                                message: INPUT.NAME.ERRORS.MIN.MESSAGE,
                            },
                        },
                    },
                    {
                        label: "Email",
                        key: "email",
                        placeholder: INPUT.EMAIL.PLACEHOLDER,
                        type: DATA_TYPE.TEXT,
                        validators: {
                            required: INPUT.EMAIL.ERRORS.REQUIRED,
                            pattern: {
                                value: INPUT.EMAIL.ERRORS.PATTERN.KEY,
                                message: INPUT.EMAIL.ERRORS.PATTERN.MESSAGE,
                            },
                        },
                    },
                ],
                defaultArrayFieldStruct: {
                    email: "",
                    name: "",
                },
                initialValue: [
                    // {
                    //     email: props.user.email,
                    //     name: props.user.firstName,
                    // },
                ],
            },
            {
                label: INPUT.NOTES.LABEL,
                key: INPUT.NOTES.KEY,
                placeholder: INPUT.NOTES.PLACEHOLDER,
                tooltip: INPUT.NOTES.TOOLTIP,
                type: DATA_TYPE.RICH_TEXT,
                breakpoints: {
                    xs: 12,
                },
            },
        ],
    },
    SHOPS: {
        COLLECTION: "shops",
        NAME: "shop",
        ICON: <FaShoppingCart size={25} />,
        ORDER: 1,
        COLOR: DEFAULT_SITE.THEME.COLOR.LIGHT.SECONDARY,
        STRUCTURE: [
            {
                label: "Shop Name",
                key: INPUT.NAME.KEY,
                shown: true,
                type: DATA_TYPE.TEXT,
                tooltip: "Do not include the name of the client in the shop name unless absolutely necessary. The shop is tied to the client automatically!",
                placeholder: "Holiday Gift",
                breakpoints: {
                    xs: 12,
                    sm: 6,
                    md: 4,
                    lg: 3,
                },
                validators: {
                    required: INPUT.NAME.ERRORS.REQUIRED,
                    maxLength: {
                        value: INPUT.NAME.ERRORS.MAX.KEY,
                        message: INPUT.NAME.ERRORS.MAX.MESSAGE,
                    },
                    minLength: {
                        value: INPUT.NAME.ERRORS.MIN.KEY,
                        message: INPUT.NAME.ERRORS.MIN.MESSAGE,
                    },
                },
            },
            {
                label: "Shop SKU Prefix",
                key: "skuPrefix",
                tooltip: "This will be just after the client prefix for a product's SKU related to this shop, such as 'Birthday Bash' being 'BDAYB', which will becomes 'FB-BDAYB-TSHIRT' for a specific product.",
                type: DATA_TYPE.TEXT,
                placeholder: "HOL",
                breakpoints: {
                    xs: 12,
                    sm: 6,
                    md: 4,
                    lg: 3,
                },
                validators: {
                    required: "A SKU prefix is required.",
                    maxLength: {
                        value: 10,
                        message: "The SKU prefix must be at most 10 characters",
                    },
                    minLength: {
                        value: 1,
                        message: "The SKU prefix must be at least 1 characters",
                    },
                },
            },
            {
                label: "Client",
                key: "client",
                shown: true,
                parentCollection: "clients",
                type: DATA_TYPE.SELECT,
                breakpoints: {
                    xs: 12,
                    sm: 6,
                    md: 4,
                    lg: 3,
                },
                options: ["Loading..."],
                validators: {
                    required: "A client must be provided.",
                },
            },
            {
                label: "Main Store POC",
                key: "poc",
                tooltip: "Who is main point of contact with the client that you are working with on this store?",
                dependency: "client",
                type: DATA_TYPE.SELECT,
                breakpoints: {
                    xs: 12,
                    sm: 6,
                    md: 4,
                    lg: 3,
                },
                options: ["Loading..."],
                validators: {
                    required: "A main POC must be provided.",
                },
            },
            {
                label: "Description",
                key: "description",
                tooltip: "This is just a brief description of the purpose of this shop for your project.",
                type: DATA_TYPE.TEXTAREA,
                placeholder: "This shop is for the Holiday Gift 2020 campaign, which will be a gift guide for the holiday season for ACME's internal employees only.",
                breakpoints: {
                    xs: 12,
                },
                validators: {
                    required: INPUT.BODY.ERRORS.REQUIRED,
                    maxLength: {
                        value: INPUT.BODY.ERRORS.MAX.KEY,
                        message: INPUT.BODY.ERRORS.MAX.MESSAGE,
                    },
                    minLength: {
                        value: INPUT.BODY.ERRORS.MIN.KEY,
                        message: INPUT.BODY.ERRORS.MIN.MESSAGE,
                    },
                },
            },
            {
                label: "Internal Contacts",
                key: "internalContacts",
                tooltip: "Who else at Canary is working on this project? These emails will be added to the CC field of any emails related to the store.",
                breakpoints: {
                    xs: 12,
                },
                type: DATA_TYPE.ARRAY,
                validators: {
                    required: "Internal contacts are required!",
                    minLength: {
                        value: 1,
                        message: "Please add at least 1 internal contact.",
                    },
                    maxLength: {
                        value: 10,
                        message: "Please add no more than 10 internal contacts.",
                    },
                },
                subColumns: [
                    {
                        label: "Bird",
                        key: "bird",
                        type: DATA_TYPE.SELECT,
                        options: ["Loading..."],
                        userLookup: true,
                        noDups: true,
                        validators: {
                            required: "An internal contact must be provided.",
                        },
                    },
                ],
                defaultArrayFieldStruct: {
                    bird: "",
                },
                initialValue: [],
            },
            {
                label: "Draft Date",
                key: "draftTimestamp",
                tooltip: "When does the client want to see the 1st draft of the shop build?",
                type: DATA_TYPE.TIMESTAMP,
                breakpoints: {
                    xs: 12,
                    sm: 6,
                    md: 4,
                },
            },
            {
                label: "Live Date",
                key: "liveTimestamp",
                tooltip: "When does the shop plan to go live to visitors?",
                type: DATA_TYPE.TIMESTAMP,
                breakpoints: {
                    xs: 12,
                    sm: 6,
                    md: 4,
                },
            },
            {
                label: "Close Date",
                key: "closeTimestamp",
                tooltip: "When do you want the shop to close? We will send an email notification to manually close the store OR automatically close the store, depending on the shop platform.",
                type: DATA_TYPE.TIMESTAMP,
                breakpoints: {
                    xs: 12,
                    sm: 6,
                    md: 4,
                },
                initialValue: "",
            },
            {
                label: "Shop URL",
                key: INPUT.URL.KEY,
                tooltip: "This is the live URL where the shop lives on the internet.",
                isUrl: true,
                type: DATA_TYPE.TEXT,
                placeholder: INPUT.URL.PLACEHOLDER,
                breakpoints: {
                    xs: 12,
                    sm: 6,
                    md: 4,
                },
                validators: {
                    maxLength: {
                        value: INPUT.URL.ERRORS.MAX.KEY,
                        message: INPUT.URL.ERRORS.MAX.MESSAGE,
                    },
                    minLength: {
                        value: INPUT.URL.ERRORS.MIN.KEY,
                        message: INPUT.URL.ERRORS.MIN.MESSAGE,
                    },
                    pattern: {
                        value: INPUT.URL.ERRORS.PATTERN.KEY,
                        message: INPUT.URL.ERRORS.PATTERN.MESSAGE,
                    },
                },
            },
            {
                label: "Platform",
                key: "platform",
                shown: true,
                type: DATA_TYPE.SELECT,
                breakpoints: {
                    xs: 12,
                    sm: 6,
                    md: 4,
                },
                options: [
                    "BrightSites",
                    "OrderPromos",
                    "Shopify",
                    "Gift Ship Form",
                ],
            },
            {
                label: "Status",
                key: "status",
                shown: true,
                type: DATA_TYPE.SELECT,
                breakpoints: {
                    xs: 12,
                    sm: 6,
                    md: 4,
                },
                options: [
                    "Proposed",
                    "Building",
                    "Live",
                    "Closed",
                ],
            },
            {
                label: "Use cases",
                key: "useCases",
                tooltip: "How is this shop going to be used by customers shopping?",
                type: DATA_TYPE.CHECKBOX,
                breakpoints: {
                    xs: 12,
                    sm: 6,
                },
                options: [
                    "Event Items",
                    "Sales Gifts for Clients",
                    "Internal Team Gift for Employees",
                    "New Hire Kits",
                    "Employee Recognition Gifts",
                    "Anniversary Program / Years of Service Gifts",
                    "Employee Personal Purchases",
                    "Other",
                ],
            },
            {
                label: "Customer Product Payment",
                key: "paymentType",
                tooltip: "How is the customer visiting the store going to checkout with the item(s)?",
                type: DATA_TYPE.CHECKBOX,
                breakpoints: {
                    xs: 12,
                    sm: 6,
                },
                options: [
                    "Product's Price set to $0 (Free gift)",
                    "Preloaded Account Balance",
                    "Coupon Codes",
                    "Gift Cards",
                    "Credit Cards",
                    "Other",
                ],
            },
            {
                label: "Estimated # of Users",
                key: "estimatedUsers",
                type: DATA_TYPE.NUMBER,
                placeholder: 1000,
                breakpoints: {
                    xs: 12,
                    sm: 6,
                    md: 4,
                    lg: 3,
                },
            },
            {
                label: "Account Restrictions",
                key: "restrictions",
                tooltip: "How will users be permitted to access this store?",
                type: DATA_TYPE.SELECT,
                breakpoints: {
                    xs: 12,
                    sm: 6,
                    md: 4,
                    lg: 3,
                },
                options: [
                    "No Restrictions (open to anyone with the shop URL)",
                    "Guest List (Predefined list of emails or codes that lets customer in)",
                    "Permissioned (Users placed in groups manually)",
                    "SSO (Single Sign On like Okta)",
                    "Other",
                ],
            },
            {
                label: "Ship Locations",
                key: "shipLocations",
                type: DATA_TYPE.SELECT,
                breakpoints: {
                    xs: 12,
                    sm: 6,
                    md: 4,
                    lg: 3,
                },
                options: [
                    "Domestic",
                    "International",
                    "Both",
                ],
            },
            {
                label: "Product Stocking",
                key: "stocking",
                tooltip: "On Demand = Product purchased after orders are received. Inventoried = Pre-purchase inventory and cannot oversell. ",
                type: DATA_TYPE.SELECT,
                breakpoints: {
                    xs: 12,
                    sm: 6,
                    md: 4,
                    lg: 3,
                },
                options: [
                    "Inventoried",
                    "On Demand",
                    "Other",
                ],
            },
            {
                label: "Event Frequency",
                key: "eventFreq",
                type: DATA_TYPE.SELECT,
                breakpoints: {
                    xs: 12,
                    sm: 6,
                    md: 4,
                    lg: 3,
                },
                options: [
                    "One Off (i.e. App Launch)",
                    "Annually (i.e. Holiday Gifting 2021, 2022, etc)",
                    "Ongoing (i.e. Company Swag Shop or New Hire Swag)",
                    "Other",
                ],
            },
            {
                label: "NetSuite PO #",
                key: "nsPo",
                shown: true,
                type: DATA_TYPE.NUMBER,
                placeholder: 12345678,
                breakpoints: {
                    xs: 12,
                    sm: 6,
                    md: 4,
                    lg: 3,
                },
            },
            {
                label: "Predefined Budget",
                key: "budget",
                tooltip: "Does the client have a budget they informed you about as the project starts? Some projects might not have this, so is optional!",
                type: DATA_TYPE.TEXT,
                placeholder: "$3 million",
                breakpoints: {
                    xs: 12,
                    sm: 6,
                    md: 4,
                    lg: 3,
                },
            },
            {
                label: "SharePoint Folder Link",
                key: "sharepoint",
                tooltip: "This is where form data will be uploaded. Must be the same as Project Name.",
                type: DATA_TYPE.TEXT,
                placeholder: INPUT.URL.PLACEHOLDER,
                breakpoints: {
                    xs: 12,
                    sm: 6,
                    md: 4,
                    lg: 3,
                },
                isUrl: true,
                validators: {
                    maxLength: {
                        value: INPUT.URL.ERRORS.MAX.KEY,
                        message: INPUT.URL.ERRORS.MAX.MESSAGE,
                    },
                    minLength: {
                        value: INPUT.URL.ERRORS.MIN.KEY,
                        message: INPUT.URL.ERRORS.MIN.MESSAGE,
                    },
                    pattern: {
                        value: INPUT.URL.ERRORS.PATTERN.KEY,
                        message: INPUT.URL.ERRORS.PATTERN.MESSAGE,
                    },
                },
            },
            {
                label: INPUT.NOTES.LABEL,
                key: INPUT.NOTES.KEY,
                placeholder: INPUT.NOTES.PLACEHOLDER,
                tooltip: INPUT.NOTES.TOOLTIP,
                type: DATA_TYPE.RICH_TEXT,
                breakpoints: {
                    xs: 12,
                },
            },
        ],
    },
    PRODUCTS: {
        COLLECTION: "products",
        NAME: "product",
        ICON: <FaTshirt size={25} />,
        ORDER: 2,
        COLOR: DEFAULT_SITE.THEME.COLOR.LIGHT.TERTIARY,
        STRUCTURE: [
            {
                label: "Name",
                key: "name",
                shown: true,
                type: DATA_TYPE.TEXT,
                tooltip: "Do not include the colors in the name, this will be represented in the variants section.",
                placeholder: "Marine Layer Cropped T-Shirt",
                breakpoints: {
                    xs: 12,
                    sm: 6,
                },
                validators: {
                    required: INPUT.NAME.ERRORS.REQUIRED,
                    maxLength: {
                        value: INPUT.NAME.ERRORS.MAX.KEY,
                        message: INPUT.NAME.ERRORS.MAX.MESSAGE,
                    },
                    minLength: {
                        value: INPUT.NAME.ERRORS.MIN.KEY,
                        message: INPUT.NAME.ERRORS.MIN.MESSAGE,
                    },
                },
            },
            {
                label: "SKU",
                key: "sku",
                shown: true,
                type: DATA_TYPE.TEXT,
                placeholder: "ML-TEE",
                breakpoints: {
                    xs: 12,
                    sm: 6,
                },
                validators: {
                    required: "A SKU is required.",
                    maxLength: {
                        value: 50,
                        message: "SKU must be at most 50 characters long.",
                    },
                    minLength: {
                        value: 2,
                        message: "SKU must be at least 2 characters long.",
                    },
                },
            },
            {
                label: "Images",
                key: "images",
                type: DATA_TYPE.IMAGES,
                breakpoints: {
                    xs: 12,
                },
                // validators:{
                //     required: "Images are required.",
                // },
            },
            {
                label: "Client",
                key: "client",
                shown: true,
                parentCollection: "clients",
                type: DATA_TYPE.SELECT,
                breakpoints: {
                    xs: 12,
                    sm: 6,
                    md: 4,
                },
                options: ["Loading..."],
                validators: {
                    required: "A client is required.",
                },
            },
            {
                label: "Shop",
                key: "shop",
                shown: true,
                parentCollection: "shops",
                type: DATA_TYPE.SELECT,
                options: ["Loading..."],
                breakpoints: {
                    xs: 12,
                    sm: 6,
                    md: 4,
                },
            },
            {
                label: "Category",
                key: "category",
                placeholder: "Sale",
                tooltip: "This would be the category section the product sits in within a store.",
                shown: true,
                type: DATA_TYPE.TEXT,
                breakpoints: {
                    xs: 12,
                    sm: 6,
                    md: 4,
                },
            },
            {
                label: "Description",
                key: "description",
                type: DATA_TYPE.RICH_TEXT,
                placeholder: "Description verbiage added to the product page to detail the product.",
                breakpoints: {
                    xs: 12,
                },
            },
            {
                label: "Inventory",
                key: "inventory",
                tooltip: "If variant set, this is ignored because variant sub-values overrides this value.",
                type: DATA_TYPE.NUMBER,
                placeholder: 10,
                breakpoints: {
                    xs: 12,
                    sm: 6,
                    md: 4,
                    lg: 3,
                },
            },
            {
                label: "Sold",
                key: "sold",
                tooltip: "If variant set, this is ignored because variant sub-values overrides this value.",
                type: DATA_TYPE.NUMBER,
                placeholder: 0,
                breakpoints: {
                    xs: 12,
                    sm: 6,
                    md: 4,
                    lg: 3,
                },
            },
            {
                label: "Non-tangible?",
                key: "nonTangible",
                type: DATA_TYPE.RADIO,
                tooltip: "Non-tangible products are non-physical products such as a donation, a gift card, or a digital download, and thus do not require shipping.",
                breakpoints: {
                    xs: 12,
                    sm: 6,
                    md: 4,
                    lg: 3,
                },
                isBool: true,
                initialValue: "false",
            },
            {
                label: "Is Hidden?",
                key: "isHidden",
                type: DATA_TYPE.RADIO,
                tooltip: "Is this product shown on the front end yet?",
                breakpoints: {
                    xs: 12,
                    sm: 6,
                    md: 4,
                    lg: 3,
                },
                isBool: true,
                initialValue: "false",
            },
            {
                label: "Variant",
                key: "variant",
                tooltip: "What option choices are we offering for the product, such as color, size, fit, etc.",
                type: DATA_TYPE.OBJECT,
                nestedColumns: [
                    {
                        label: INPUT.NAME.LABEL,
                        key: INPUT.NAME.KEY,
                        placeholder: "Size",
                        type: DATA_TYPE.TEXT,
                        breakpoints: {
                            sm: 12,
                            md: 6,
                        },
                        validators: {
                            maxLength: {
                                value: INPUT.NAME.ERRORS.MAX.KEY,
                                message: INPUT.NAME.ERRORS.MAX.MESSAGE,
                            },
                        },
                    },
                    {
                        label: "Option Input Layout",
                        key: "inputLayout",
                        tooltip: "This is how the input selector for the variant option choices will display for the customer on the front-end.",
                        type: DATA_TYPE.SELECT,
                        breakpoints: {
                            sm: 12,
                            md: 6,
                        },
                        options: [
                            VARIANT_LAYOUT.SELECT,
                            VARIANT_LAYOUT.RADIOS,
                            VARIANT_LAYOUT.BUTTONS,
                        ],
                    },
                    {
                        label: "Options",
                        key: "options",
                        breakpoints: {
                            xs: 12,
                        },
                        type: DATA_TYPE.ARRAY,
                        subColumns: [
                            {
                                label: "Name",
                                key: "name",
                                placeholder: "Small",
                                breakpoints: {
                                    xs: 12,
                                    sm: 6,
                                },
                                type: DATA_TYPE.TEXT,
                                validators: {
                                    required: "A name is required!",
                                },
                            },
                            {
                                label: "Sub SKU",
                                key: "subSku",
                                placeholder: "SM",
                                breakpoints: {
                                    xs: 12,
                                    sm: 6,
                                },
                                type: DATA_TYPE.TEXT,
                                validators: {
                                    required: "A sub SKU is required!",
                                },
                            },
                            {
                                label: "Inventory",
                                key: "inventory",
                                placeholder: 10,
                                breakpoints: {
                                    xs: 12,
                                    sm: 6,
                                    md: 4,
                                },
                                type: DATA_TYPE.NUMBER,
                                validators: {
                                    required: "An inventory level is required!",
                                },
                            },
                            {
                                label: "Sold",
                                key: "sold",
                                type: DATA_TYPE.NUMBER,
                                placeholder: 0,
                                breakpoints: {
                                    xs: 12,
                                    sm: 6,
                                    md: 4,
                                },
                            },
                            {
                                label: "Button Background Color",
                                key: "bgColor",
                                placeholder: INPUT.COLOR.PLACEHOLDER,
                                tooltip: "This is the background color of the button for this variant option choice, but is only shown if the layout is set to box buttons.",
                                type: DATA_TYPE.TEXT,
                                breakpoints: {
                                    xs: 12,
                                    sm: 6,
                                    md: 4,
                                },
                                validators: {
                                    validate: ((value) => value === "" || isColor(value) || INPUT.COLOR.ERRORS.VALIDATE.MESSAGE),
                                },
                            },
                            // {
                            //     label: "Chart Values",
                            //     key: "chartValues",
                            //     tooltip: "These are the values for each corresponding chart row name. So if a product has a size chart outlining the chest and waist measurements, include two chart value names.",
                            //     breakpoints: {
                            //         xs: 12,
                            //     },
                            //     shown: false,
                            //     type: DATA_TYPE.ARRAY,
                            //     subColumns: [
                            //         {
                            //             label: "Name",
                            //             key: "name",
                            //             placeholder: `31"-32"`,
                            //             breakpoints: {
                            //                 xs: 12,
                            //                 sm: 6,
                            //                 md: 4,
                            //             },
                            //             type: DATA_TYPE.TEXT,
                            //             validators: {
                            //                 required: "A name is required!",
                            //             },
                            //         },
                            //     ],
                            //     defaultArrayFieldStruct: {
                            //         name: "",
                            //     },
                            //     initialValue: [],
                            // },
                        ],
                        defaultArrayFieldStruct: {
                            name: "",
                            subSku: "",
                            inventory: 0,
                            sold: 0,
                        },
                        initialValue: [],
                    },
                    // {
                    //     label: "Chart Name",
                    //     key: "chartName",
                    //     placeholder: "Size Chart",
                    //     type: DATA_TYPE.TEXT,
                    //     breakpoints: {
                    //         sm: 12,
                    //         md: 6,
                    //     },
                    //     validators: {
                    //         required: "",
                    //         maxLength: {
                    //             value: 50,
                    //             message: "Chart name must be at most 50 characters long.",
                    //         },
                    //     },
                    // },
                    // {
                    //     label: "Chart Row Name",
                    //     key: "chartRowNames",
                    //     tooltip: "These are the labels at the beginning of each row on the far left. Usually this will be the part of the body the measurement of each option corresponds to.",
                    //     breakpoints: {
                    //         xs: 12,
                    //     },
                    //     shown: false,
                    //     type: DATA_TYPE.ARRAY,
                    //     subColumns: [
                    //         {
                    //             label: "Name",
                    //             key: "name",
                    //             placeholder: "Chest (Inches)",
                    //             breakpoints: {
                    //                 xs: 12,
                    //                 sm: 6,
                    //                 md: 4,
                    //             },
                    //             type: DATA_TYPE.TEXT,
                    //             validators: {
                    //                 required: "A name is required!",
                    //             },
                    //         },
                    //     ],
                    //     defaultArrayFieldStruct: {
                    //         name: "",
                    //     },
                    //     initialValue: [],
                    // },
                ],
            },
            {
                label: INPUT.NOTES.LABEL,
                key: INPUT.NOTES.KEY,
                placeholder: INPUT.NOTES.PLACEHOLDER,
                tooltip: INPUT.NOTES.TOOLTIP,
                type: DATA_TYPE.RICH_TEXT,
                breakpoints: {
                    xs: 12,
                },
            },
        ],
    },
    ORDERS: {
        COLLECTION: "orders",
        NAME: "order",
        ICON: <BiPackage size={25} />,
        ORDER: 3,
        COLOR: DEFAULT_SITE.THEME.COLOR.LIGHT.BLUE,
        STRUCTURE: [
            {
                label: "Client",
                key: "client",
                shown: true,
                parentCollection: "clients",
                breakpoints: {
                    xs: 12,
                    sm: 6,
                },
                type: DATA_TYPE.SELECT,
                options: ["Loading..."],
                validators: {
                    required: "A client must be provided.",
                },
            },
            {
                label: "Shop",
                key: "shop",
                shown: true,
                parentCollection: "shops",
                breakpoints: {
                    xs: 12,
                    sm: 6,
                },
                type: DATA_TYPE.SELECT,
                options: ["Loading..."],
                validators: {
                    required: "A shop must be provided.",
                },
            },
            {
                label: "Shipping Address",
                key: "shippingAddress",
                tooltip: "What is the shipping information for this order?",
                type: DATA_TYPE.OBJECT,
                nestedColumns: [
                    {
                        label: INPUT.FIRST_NAME.LABEL,
                        key: INPUT.FIRST_NAME.KEY,
                        placeholder: INPUT.FIRST_NAME.PLACEHOLDER,
                        type: DATA_TYPE.TEXT,
                        breakpoints: {
                            sm: 12,
                            md: 6,
                        },
                        validators: {
                            required: INPUT.FIRST_NAME.ERRORS.REQUIRED,
                            maxLength: {
                                value: INPUT.FIRST_NAME.ERRORS.MAX.KEY,
                                message: INPUT.FIRST_NAME.ERRORS.MAX.MESSAGE,
                            },
                            minLength: {
                                value: INPUT.FIRST_NAME.ERRORS.MIN.KEY,
                                message: INPUT.FIRST_NAME.ERRORS.MIN.MESSAGE,
                            },
                        },
                    },
                    {
                        label: INPUT.LAST_NAME.LABEL,
                        key: INPUT.LAST_NAME.KEY,
                        placeholder: INPUT.LAST_NAME.PLACEHOLDER,
                        type: DATA_TYPE.TEXT,
                        breakpoints: {
                            sm: 12,
                            md: 6,
                        },
                        validators: {
                            required: INPUT.LAST_NAME.ERRORS.REQUIRED,
                            maxLength: {
                                value: INPUT.LAST_NAME.ERRORS.MAX.KEY,
                                message: INPUT.LAST_NAME.ERRORS.MAX.MESSAGE,
                            },
                            minLength: {
                                value: INPUT.LAST_NAME.ERRORS.MIN.KEY,
                                message: INPUT.LAST_NAME.ERRORS.MIN.MESSAGE,
                            },
                        },
                    },
                    {
                        label: INPUT.EMAIL.LABEL,
                        key: INPUT.EMAIL.KEY,
                        placeholder: INPUT.EMAIL.PLACEHOLDER,
                        type: DATA_TYPE.TEXT,
                        breakpoints: {
                            sm: 12,
                            md: 6,
                        },
                        validators: {
                            required: INPUT.EMAIL.ERRORS.REQUIRED,
                            pattern: {
                                value: INPUT.EMAIL.ERRORS.PATTERN.KEY,
                                message: INPUT.EMAIL.ERRORS.PATTERN.MESSAGE,
                            },
                        },
                    },
                    {
                        label: INPUT.PHONE.LABEL,
                        key: INPUT.PHONE.KEY,
                        placeholder: INPUT.PHONE.PLACEHOLDER,
                        type: DATA_TYPE.TEXT,
                        breakpoints: {
                            sm: 12,
                            md: 6,
                        },
                        validators: {
                            required: INPUT.PHONE.ERRORS.REQUIRED,
                            maxLength: {
                                value: INPUT.PHONE.ERRORS.MAX.KEY,
                                message: INPUT.PHONE.ERRORS.MAX.MESSAGE,
                            },
                            minLength: {
                                value: INPUT.PHONE.ERRORS.MIN.KEY,
                                message: INPUT.PHONE.ERRORS.MIN.MESSAGE,
                            },
                        },
                    },
                    {
                        label: INPUT.ADDRESS.LINE1.LABEL,
                        key: INPUT.ADDRESS.LINE1.KEY,
                        placeholder: INPUT.ADDRESS.LINE1.PLACEHOLDER,
                        type: DATA_TYPE.TEXT,
                        breakpoints: {
                            sm: 12,
                        },
                        validators: {
                            required: INPUT.ADDRESS.LINE1.ERRORS.REQUIRED,
                            maxLength: {
                                value: INPUT.ADDRESS.LINE1.ERRORS.MAX.KEY,
                                message: INPUT.ADDRESS.LINE1.ERRORS.MAX.MESSAGE,
                            },
                            minLength: {
                                value: INPUT.ADDRESS.LINE1.ERRORS.MIN.KEY,
                                message: INPUT.ADDRESS.LINE1.ERRORS.MIN.MESSAGE,
                            },
                        },
                    },
                    {
                        label: INPUT.ADDRESS.LINE2.LABEL,
                        key: INPUT.ADDRESS.LINE2.KEY,
                        placeholder: INPUT.ADDRESS.LINE2.PLACEHOLDER,
                        type: DATA_TYPE.TEXT,
                        breakpoints: {
                            sm: 12,
                        },
                        validators: {
                            maxLength: {
                                value: INPUT.ADDRESS.LINE2.ERRORS.MAX.KEY,
                                message: INPUT.ADDRESS.LINE2.ERRORS.MAX.MESSAGE,
                            },
                        },
                    },
                    {
                        label: INPUT.ADDRESS.LINE3.LABEL,
                        key: INPUT.ADDRESS.LINE3.KEY,
                        placeholder: INPUT.ADDRESS.LINE3.PLACEHOLDER,
                        type: DATA_TYPE.TEXT,
                        breakpoints: {
                            sm: 12,
                        },
                        validators: {
                            maxLength: {
                                value: INPUT.ADDRESS.LINE3.ERRORS.MAX.KEY,
                                message: INPUT.ADDRESS.LINE3.ERRORS.MAX.MESSAGE,
                            },
                        },
                    },
                    {
                        label: INPUT.ADDRESS.CITY.LABEL,
                        key: INPUT.ADDRESS.CITY.KEY,
                        placeholder: INPUT.ADDRESS.CITY.PLACEHOLDER,
                        type: DATA_TYPE.TEXT,
                        breakpoints: {
                            xs: 12,
                            sm: 6,
                            md: 4,
                            lg: 3,
                        },
                        validators: {
                            required: INPUT.ADDRESS.CITY.ERRORS.REQUIRED,
                            maxLength: {
                                value: INPUT.ADDRESS.CITY.ERRORS.MAX.KEY,
                                message: INPUT.ADDRESS.CITY.ERRORS.MAX.MESSAGE,
                            },
                            minLength: {
                                value: INPUT.ADDRESS.CITY.ERRORS.MIN.KEY,
                                message: INPUT.ADDRESS.CITY.ERRORS.MIN.MESSAGE,
                            },
                        },
                    },
                    {
                        label: INPUT.ADDRESS.STATE.LABEL,
                        key: INPUT.ADDRESS.STATE.KEY,
                        placeholder: INPUT.ADDRESS.STATE.PLACEHOLDER,
                        type: DATA_TYPE.TEXT,
                        breakpoints: {
                            xs: 12,
                            sm: 6,
                            md: 4,
                            lg: 3,
                        },
                        validators: {
                            required: INPUT.ADDRESS.STATE.ERRORS.REQUIRED,
                            maxLength: {
                                value: INPUT.ADDRESS.STATE.ERRORS.MAX.KEY,
                                message: INPUT.ADDRESS.STATE.ERRORS.MAX.MESSAGE,
                            },
                            minLength: {
                                value: INPUT.ADDRESS.STATE.ERRORS.MIN.KEY,
                                message: INPUT.ADDRESS.STATE.ERRORS.MIN.MESSAGE,
                            },
                        },
                    },
                    {
                        label: INPUT.ADDRESS.ZIP.LABEL,
                        key: INPUT.ADDRESS.ZIP.KEY,
                        placeholder: INPUT.ADDRESS.ZIP.PLACEHOLDER,
                        type: DATA_TYPE.TEXT,
                        breakpoints: {
                            xs: 12,
                            sm: 6,
                            md: 4,
                            lg: 3,
                        },
                        validators: {
                            required: INPUT.ADDRESS.ZIP.ERRORS.REQUIRED,
                            maxLength: {
                                value: INPUT.ADDRESS.ZIP.ERRORS.MAX.KEY,
                                message: INPUT.ADDRESS.ZIP.ERRORS.MAX.MESSAGE,
                            },
                            minLength: {
                                value: INPUT.ADDRESS.ZIP.ERRORS.MIN.KEY,
                                message: INPUT.ADDRESS.ZIP.ERRORS.MIN.MESSAGE,
                            },
                        },
                    },
                    {
                        label: INPUT.ADDRESS.COUNTRY.LABEL,
                        key: INPUT.ADDRESS.COUNTRY.KEY,
                        placeholder: INPUT.ADDRESS.COUNTRY.PLACEHOLDER,
                        tooltip: "County must be 2 character ISO code. Click to view examples.",
                        tooltipLink: "https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2#Officially_assigned_code_elements",
                        type: DATA_TYPE.TEXT,
                        breakpoints: {
                            xs: 12,
                            sm: 6,
                            md: 4,
                            lg: 3,
                        },
                        validators: {
                            required: INPUT.ADDRESS.COUNTRY.ERRORS.REQUIRED,
                            maxLength: {
                                value: INPUT.ADDRESS.COUNTRY.ERRORS.MAX.KEY,
                                message: INPUT.ADDRESS.COUNTRY.ERRORS.MAX.MESSAGE,
                            },
                            minLength: {
                                value: INPUT.ADDRESS.COUNTRY.ERRORS.MIN.KEY,
                                message: INPUT.ADDRESS.COUNTRY.ERRORS.MIN.MESSAGE,
                            },
                        },
                    },
                ],
            },
            {
                label: "Products",
                key: "products",
                breakpoints: {
                    xs: 12,
                },
                type: DATA_TYPE.ARRAY,
                validators: {
                    required: "A product choice is required!",
                    minLength: {
                        value: 1,
                        message: "Please add at least 1 product choice.",
                    },
                },
                subColumns: [
                    {
                        label: "Product",
                        key: "product",
                        breakpoints: {
                            xs: 12,
                            sm: 6,
                            md: 4,
                        },
                        type: DATA_TYPE.SELECT,
                        options: ["Loading..."],
                        noDups: true,
                        productLookup: true,
                        validators: {
                            required: "A product must be selected.",
                        },
                    },
                    {
                        label: "Quantity",
                        key: "quantity",
                        placeholder: 0,
                        breakpoints: {
                            xs: 12,
                            sm: 6,
                            md: 4,
                        },
                        type: DATA_TYPE.NUMBER,
                        validators: {
                            required: "A quantity is required!",
                        },
                    },
                ],
                defaultArrayFieldStruct: {
                    product: "",
                    quantity: 0,
                },
                initialValue: [],
            },
            {
                label: INPUT.NOTES.LABEL,
                key: INPUT.NOTES.KEY,
                placeholder: INPUT.NOTES.PLACEHOLDER,
                tooltip: INPUT.NOTES.TOOLTIP,
                type: DATA_TYPE.RICH_TEXT,
                breakpoints: {
                    xs: 12,
                },
            },
        ],
    },
    PAGES: {
        COLLECTION: "pages",
        NAME: "page",
        ICON: <RiPagesLine size={25} />,
        COLOR: DEFAULT_SITE.THEME.COLOR.LIGHT.GREEN,
        STRUCTURE: [
            {
                label: "Name",
                key: "name",
                shown: true,
                type: DATA_TYPE.TEXT,
                tooltip: "The URL path will be formed from this name.",
                placeholder: "FAQ",
                breakpoints: {
                    xs: 12,
                    sm: 6,
                },
                validators: {
                    required: INPUT.NAME.ERRORS.REQUIRED,
                    maxLength: {
                        value: INPUT.NAME.ERRORS.MAX.KEY,
                        message: INPUT.NAME.ERRORS.MAX.MESSAGE,
                    },
                    minLength: {
                        value: INPUT.NAME.ERRORS.MIN.KEY,
                        message: INPUT.NAME.ERRORS.MIN.MESSAGE,
                    },
                },
            },
            {
                label: "Is internal?",
                key: "isInternal",
                tooltip: "Is this page intended for internal users only? Or can anyone view this page?",
                breakpoints: {
                    xs: 12,
                    sm: 6,
                },
                type: DATA_TYPE.RADIO,
                isBool: true,
                initialValue: "false",
            },
            {
                label: "Body",
                key: "body",
                shown: true,
                type: DATA_TYPE.RICH_TEXT,
                placeholder: INPUT.BODY.PLACEHOLDER,
                breakpoints: {
                    xs: 12,
                },
                validators: {
                    required: INPUT.BODY.ERRORS.REQUIRED,
                },
            },
        ],
    },
    USERS: {
        COLLECTION: "users",
        NAME: "user",
        STRUCTURE: [
            {
                label: INPUT.FIRST_NAME.LABEL,
                key: INPUT.FIRST_NAME.KEY,
                type: DATA_TYPE.TEXT,
                shown: true,
                uneditable: true,
            },
            {
                label: INPUT.LAST_NAME.LABEL,
                key: INPUT.LAST_NAME.KEY,
                type: DATA_TYPE.TEXT,
                shown: true,
                uneditable: true,
            },
            {
                label: INPUT.EMAIL.LABEL,
                key: INPUT.EMAIL.KEY,
                type: DATA_TYPE.TEXT,
                shown: true,
                uneditable: true,
            },
            {
                label: INPUT.PHONE.LABEL,
                key: INPUT.PHONE.KEY,
                type: DATA_TYPE.TEXT,
                shown: true,
                uneditable: true,
            },
            {
                label: "Role",
                key: "role",
                type: DATA_TYPE.SELECT,
                shown: true,
                parentCollection: "roles",
                options: ["Loading..."],
            },
            // {
            //     label: INPUT.NOTES.LABEL,
            //     key: INPUT.NOTES.KEY,
            //     placeholder: INPUT.NOTES.PLACEHOLDER,
            //     tooltip: INPUT.NOTES.TOOLTIP,
            //     type: DATA_TYPE.RICH_TEXT,
            //     breakpoints: {
            //         xs: 12,
            //     },
            // },
        ],
    },
    ROLES: {
        COLLECTION: "roles",
        NAME: "role",
        STRUCTURE: [
            {
                label: "Role Name",
                key: "name",
                shown: true,
                breakpoints: {
                    xs: 12,
                    sm: 6,
                },
                type: DATA_TYPE.TEXT,
                placeholder: "Team Edward",
                validators: {
                    required: INPUT.NAME.ERRORS.REQUIRED,
                    maxLength: {
                        value: INPUT.NAME.ERRORS.MAX.KEY,
                        message: INPUT.NAME.ERRORS.MAX.MESSAGE,
                    },
                    minLength: {
                        value: INPUT.NAME.ERRORS.MIN.KEY,
                        message: INPUT.NAME.ERRORS.MIN.MESSAGE,
                    },
                },
            },
            {
                label: "Admin role?",
                key: "isAdmin",
                tooltip: "Does this role allow users to access the admin dashboard? Or is it a front facing, non-admin role? If this is 'No' then filters and permissions below are ignored.",
                breakpoints: {
                    xs: 12,
                    sm: 6,
                },
                type: DATA_TYPE.RADIO,
                isBool: true,
                initialValue: "false",
                // validators: {
                //     required: "We need to know if this is an admin type role or not.",
                // },
            },
            {
                label: "Item Collection Permissions",
                key: "permissions",
                tooltip: "Setting the item collection permissions would limit what actions the user can make on the database. Remember to include any dependent collections reads for drop-downs, etc!",
                breakpoints: {
                    xs: 12,
                },
                type: DATA_TYPE.ARRAY,
                validators: {
                    required: "An item permission is required.",
                },
                subColumns: [
                    {
                        label: "Item Actions",
                        key: "itemActions",
                        tooltip: "Actions that the users in this role can use on data within this collection. CC will overwrite BCC on create in an email!",
                        type: DATA_TYPE.CHECKBOX,
                        options: [
                            CRUD.READ,
                            CRUD.CREATE,
                            CRUD.UPDATE,
                            CRUD.DELETE,
                            CRUD.CC_ON_CREATE,
                            CRUD.BCC_ON_CREATE,
                        ],
                        validators: {
                            required: "A item action set must be provided.",
                        },
                    },
                    {
                        label: "Item Collection Key",
                        key: "itemKey",
                        tooltip: "The item collection key would be the main item management tab that the admin can view and the collection on the database.",
                        type: DATA_TYPE.SELECT,
                        options: [
                            "messages",
                            "feedback",
                            "clients",
                            "shops",
                            "products",
                            "orders",
                        ],
                        noDups: true,
                        validators: {
                            required: "An item key must be provided.",
                        },
                    },
                ],
                defaultArrayFieldStruct: {
                    itemKey: "",
                    itemActions: "",
                },
                initialValue: [],
            },
            {
                label: "Filter",
                key: "filter",
                tooltip: "An item filter will allow you to limit what exact data this role can interact with. If none provided, all sub-data will be usable. ",
                type: DATA_TYPE.OBJECT,
                breakpoints: {
                    xs: 12,
                },
                nestedColumns: [
                    {
                        label: "Apply filter?",
                        key: "applyFilter",
                        tooltip: "Do we want to activate this filter or not?",
                        breakpoints: {
                            xs: 12,
                        },
                        type: DATA_TYPE.RADIO,
                        isBool: true,
                        initialValue: "false",
                        // validators: {
                        //     required: "We need to know if you want to apply this filter or not.",
                        // },
                    },
                    {
                        label: "Main Collection Key",
                        key: "filterKey",
                        noDups: true,
                        tooltip: "The filter collection key would be the main item management tab that the admin can view and the collection on the database like 'products'.",
                        breakpoints: {
                            xs: 12,
                            sm: 6,
                        },
                        type: DATA_TYPE.SELECT,
                        options: [
                            "messages",
                            "feedback",
                            "clients",
                            "shops",
                            "products",
                            "orders",
                        ],
                    },
                    {
                        label: "Dependent Collection Keys",
                        key: "depKeys",
                        tooltip: "Which collection keys should we apply the dependent column key to? This value does not need to include the main collection key above.",
                        breakpoints: {
                            xs: 12,
                            sm: 6,
                        },
                        type: DATA_TYPE.ARRAY,
                        subColumns: [
                            {
                                label: "Collection key",
                                key: "depKey",
                                noDups: true,
                                type: DATA_TYPE.SELECT,
                                options: [
                                    "messages",
                                    "feedback",
                                    "clients",
                                    "shops",
                                    "products",
                                    "orders",
                                ],
                                validators: {
                                    required: "A collection key must be provided.",
                                },
                            },
                        ],
                        defaultArrayFieldStruct: {
                            columnValue: "",
                        },
                        initialValue: [],
                    },
                    {
                        label: "Main Field Column Key",
                        key: "columnKey",
                        tooltip: "Filter column key would be the key string of the field that you want to limit, such as a client's 'name' field.",
                        placeholder: "name",
                        breakpoints: {
                            xs: 12,
                            sm: 6,
                        },
                        type: DATA_TYPE.TEXT,
                    },
                    {
                        label: "Dependent Field Column Key",
                        key: "depColKey",
                        tooltip: "The dependent column key would be another field key that this data is sorted by in ANOTHER item data collection, such as in 'shops' collection, 'clients' are sorted by the 'client' key, not 'name' key, so you would put 'client' here.",
                        breakpoints: {
                            xs: 12,
                            sm: 6,
                        },
                        placeholder: "client",
                        type: DATA_TYPE.TEXT,
                    },
                    {
                        label: "Field Column Values",
                        key: "columnValues",
                        tooltip: "Filter column value would be the expected value to limit like client name such as 'Twilio' or 'Google'.",
                        type: DATA_TYPE.ARRAY,
                        subColumns: [
                            {
                                label: "Value",
                                key: "columnValue",
                                breakpoints: {
                                    xs: 12,
                                    sm: 6,
                                    md: 4,
                                },
                                placeholder: "ACME",
                                type: DATA_TYPE.TEXT,
                                validators: {
                                    required: "A filter column value must be provided.",
                                },
                            },
                        ],
                        defaultArrayFieldStruct: {
                            columnValue: "",
                        },
                        initialValue: [],
                    },
                ],
            },
            {
                label: INPUT.NOTES.LABEL,
                key: INPUT.NOTES.KEY,
                placeholder: INPUT.NOTES.PLACEHOLDER,
                tooltip: INPUT.NOTES.TOOLTIP,
                type: DATA_TYPE.RICH_TEXT,
                breakpoints: {
                    xs: 12,
                },
            },
        ],
    },
};
